import React, { useState } from "react"
import { gql, useMutation } from "@apollo/client"
import Router from "next/router"
import { Formik, Form } from "formik"
import yup from "app/lib/yup.validations"
import { Heading, Link, Button, Text } from "app/wellzesta-ui"
import Logo from "app/common/icons/LifeLogo"
import InternalLink from "app/common/InternalLink"
import Chevron from "app/common/icons/Chevron"
import CheckEmailStep from "./CheckEmailStep"
import FormField from "app/common/form/FormField"
import { useProduct } from "app/lib/Product/ProductContext"

const sendMagicLinkEmailMutation = gql`
  mutation SendMagicLinkEmail($email: String!, $productName: ProductNames!) {
    sendMagicLinkEmail(email: $email, productName: $productName)
  }
`

export default function Login() {
  const { isReach, isConnect, isLife } = useProduct()
  const hideCommunityLogin = isReach || isConnect
  const hidePinLogin = isConnect
  const hideEasySignIn = isLife

  const [email, setEmail] = useState("")
  const [currentStep, setCurrentStep] = useState("login")

  const bgColor =
    currentStep === "login"
      ? "bg-white md:bg-primary-darken"
      : "bg-primary-darken"

  return (
    <div
      className={`min-h-screen relative flex items-center justify-center ${bgColor}`}
    >
      <div
        className={`absolute left-0 top-0 py-4 ${
          currentStep === "login" ? "hidden" : "block"
        }`}
      >
        <Button
          className="flex justify-center items-center"
          onClick={() => setCurrentStep("login")}
          size="sm"
        >
          <Chevron className="w-3 mr-3" />
          Back
        </Button>
      </div>
      <div className={currentStep !== "login" ? "hidden" : "block"}>
        <LoginForm
          setCurrentStep={setCurrentStep}
          setEmail={setEmail}
          hideCommunityLogin={hideCommunityLogin}
          hidePinLogin={hidePinLogin}
          hideEasySignIn={hideEasySignIn}
        />
      </div>
      <div className={currentStep !== "check-email" ? "hidden" : "block"}>
        <CheckEmailStep
          email={email}
          setCurrentStep={setCurrentStep}
          hidePinLogin={hidePinLogin}
        />
      </div>
    </div>
  )
}

const UserEmailFormSchema = yup.object().shape({
  email: yup.string().email().required().max(320),
})

function LoginForm({
  setCurrentStep,
  setEmail,
  hideCommunityLogin,
  hidePinLogin,
  hideEasySignIn,
}) {
  const { productDomain } = useProduct()
  const [sendMagicLinkEmail, { loading, error }] = useMutation(
    sendMagicLinkEmailMutation
  )

  const onSubmit = (values) => {
    setEmail(values.email)

    if (values.authType === "magic-link") {
      return sendMagicLinkEmail({
        variables: {
          email: values.email,
          productName: productDomain.toUpperCase(),
        },
      }).then(() => setCurrentStep("check-email"))
    } else {
      Router.push({
        pathname: "/pin-login",
        query: { email: values.email },
      })
    }
  }

  return (
    <div className="max-w-md min-w-xs rounded-lg bg-white flex flex-col items-center py-4 px-4 md:px-20">
      <div className="flex flex-col items-center space-y-4 mb-8 md:max-w-xs">
        <Logo className="w-20 h-20" />
        <Heading size="md" as="h1" className="text-center">
          Welcome to Wellzesta
        </Heading>
        <Text className="inline font-semibold text-primary">
          Promoting health and vitality.
        </Text>
      </div>
      {error && (
        <div
          role="alert"
          className="w-full bg-red-300 text-white text-sm font-bold p-4 uppercase"
        >
          Something went wrong! Please try again.
        </div>
      )}
      <Formik
        initialValues={{ email: "" }}
        onSubmit={onSubmit}
        validationSchema={UserEmailFormSchema}
      >
        {({ setFieldValue }) => {
          const buttons = []

          if (!hideEasySignIn) {
            buttons.push(
              <Button
                theme="inverse"
                type="submit"
                className="w-full"
                disabled={loading}
                onClick={() => {
                  setFieldValue("authType", "magic-link")
                }}
              >
                {loading ? "Requesting..." : "Easy Sign-in"}
              </Button>
            )
          }

          if (!hidePinLogin) {
            if (buttons.length) {
              buttons.push(
                <Text size="sm" className="text-gray-500">
                  or
                </Text>
              )
            }
            buttons.push(
              <Button
                theme="primary"
                className="w-full"
                type="submit"
                onClick={() => {
                  setFieldValue("authType", "login-with-pin")
                }}
              >
                Sign-in using PIN
              </Button>
            )
          }

          // always make the first button more highlighted
          if (buttons.length) {
            buttons[0] = React.cloneElement(buttons[0], { theme: "primary" })
          }

          return (
            <Form className="w-full md:max-w-xs">
              <div className="flex flex-col items-center space-y-4">
                <div className="w-full mb-2">
                  <FormField
                    type="email"
                    name="email"
                    label="Email"
                    placeholder="Type your email"
                  />
                </div>
                {buttons}
              </div>
            </Form>
          )
        }}
      </Formik>
      <div className="flex flex-col items-center w-full mt-8 space-y-4 border-t border-beige-200">
        <InternalLink
          href="/community-login"
          data-test-id="login-as-community"
          theme="primary"
          className={`underline ${hideCommunityLogin ? "hidden" : undefined}`}
        >
          Sign-in as Community
        </InternalLink>
        <Text size="sm" className="font-semibold">
          By signing in, you agree to the
          <Link
            href="https://policies.wellzesta.com/terms-of-use/"
            target="_blank"
            className="underline"
            theme="primary"
            size="md"
          >
            Terms & Conditions
          </Link>
        </Text>
      </div>
    </div>
  )
}
