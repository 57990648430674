import React from "react"
import styled from "styled-components"

const Icon = ({ className }) => (
  <svg viewBox="0 0 12 18" className={className}>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-12 -93)">
        <g transform="translate(0 84)">
          <g transform="matrix(-1 0 0 1 36 0)">
            <polygon points="0 0 36 0 36 36 0 36" />
            <polygon
              fill="currentColor"
              fillRule="nonzero"
              points="15 9 12.885 11.115 19.755 18 12.885 24.885 15 27 24 18"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const rotateDegrees = {
  left: 0,
  right: 180
}

const EnhancedIcon = styled(Icon)`
  transform: ${props => `rotate(${rotateDegrees[props.direction]}deg)`};
`

export default function Chevron({ direction = "left", className = "w-4" }) {
  return <EnhancedIcon direction={direction} className={className} />
}
